<template>
  <div class="page page__faq-category faq-category-page">
    <esmp-loader v-show="loading" fix />

    <div class="faq-category-page__header">
      <h1 v-if="categoryTitle" class="faq-category-page__title">
        {{ categoryTitle }}
      </h1>
      <div class="ticket-head__filters">
        <esmp-button
          class="typo-body-m font-weight-medium"
          view="function"
          icon="sort"
          icon-position="left"
        >
          Сортировка статей
        </esmp-button>
        <div class="ticket-head__filters-list">
          <a
            :class="[
              'ticket-head__filters-item',
              { 'ticket-head__filters-item--active': activeSortType && activeSortType.code === type.code },
            ]"
            v-for="type in FAQ_SORT_TYPES"
            :key="type.code"
            @click="activeSortType = type"
          >
            {{ type.name }}
          </a>
        </div>
      </div>
    </div>

    <template v-if="categories.length">
      <h2 class="faq-category-page__sub-title">
        Категории
      </h2>

      <faq-category-list :categories="categories" />
    </template>

    <template v-if="articles.items.length">
      <h2 class="faq-category-page__sub-title">
        Статьи
      </h2>

      <faq-article-list
        :category-id="categoryId"
        :articles="articles.items"
        :page-count="articles.totalPages"
        :active-sort-type="activeSortType"
      />
    </template>
  </div>
</template>

<script>
import { FAQ_ARTICLES_COUNT_PER_PAGE, FAQ_SORT_TYPES } from '@/constants/faq-page';
import { FaqArticleList, FaqCategoryList, faqGetParentsMixin } from '@/components/faq';
import Hub from '@/plugins/event-hub';

export default {
  name: 'FaqCategory',

  components: { FaqArticleList, FaqCategoryList },

  mixins: [faqGetParentsMixin],

  props: {
    categoryId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      categories: [],
      articles: {
        items: [],
        totalPages: null,
      },
      categoryTitle: '',
      activeSortType: FAQ_SORT_TYPES.changedDown,
    };
  },

  watch: {
    categoryId: {
      immediate: true,
      handler(value) {
        this.resetCategoryData();

        if (!value) {
          this.setDefaultBreadcrumbs();
        }

        this.getCategoryInfo(value);
      },
    },
    activeSortType() {
      this.getCategoryInfo(this.categoryId);
    },
  },

  methods: {
    async getCategoryInfo(categoryId = 0) {
      try {
        this.loading = true;

        const {
          categories,
          articles,
          title,
        } = await this.$API.faq.getCategoryInfo(+categoryId, FAQ_ARTICLES_COUNT_PER_PAGE, this.activeSortType?.code);

        await this.setBreadcrumbsFromParents(categoryId);

        this.categories = categories.map((category) => ({
          ...category,
          url: `/faq/category/${category.id}`,
          // TODO: сделать подсчет статей в категории - articlesCount
        }));

        this.articles = {
          items: articles.content.map((article) => ({
            ...article,
            url: `/faq/article/${article.id}`,
            // TODO: добавить поле для заглавного изображения статьи - imageUrl
          })),
          totalPages: articles.totalPages,
        };

        this.categoryTitle = title ?? 'База знаний';
      } catch (error) {
        if (error.code === 405) {
          this.$EsmpNotify.$show('Данная категория не доступна.', 'error');
        }
      } finally {
        this.loading = false;
      }
    },

    resetCategoryData() {
      this.categoryTitle = '';
      Hub.$emit('reset-faq-category-articles-data');
    },
  },

  created() {
    this.FAQ_SORT_TYPES = FAQ_SORT_TYPES;
  },
};
</script>

<style lang="scss">
.faq-category-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title,
  &__sub-title {
    margin-bottom: 24px;
  }
}
</style>
